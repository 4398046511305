/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
author: danish444ahmed (https://sketchfab.com/danish444ahmed)
license: CC-BY-NC-4.0 (http://creativecommons.org/licenses/by-nc/4.0/)
source: https://sketchfab.com/3d-models/logitech-mx-master-2s-mouse-e8f3235951934a01aba4e0ee3de72e71
title: Logitech MX master 2S mouse
*/

import React, { useRef } from 'react';
import { useGLTF } from '@react-three/drei';

export default function Mouse({ ...props }) {
    const group = useRef();
    const { nodes, materials } = useGLTF('/gltf/mouse/mouse.gltf');
    return (
        <group ref={group} {...props} dispose={null}>
            <group rotation={[-Math.PI / 2, 0, 0]} scale={1}>
                <mesh geometry={nodes.Object_2.geometry} material={materials.DARK_GREY_MATTE} />
                <mesh geometry={nodes.Object_3.geometry} material={materials.DARK_GREY_MATTE} />
                <mesh geometry={nodes.Object_4.geometry} material={materials.Metal} />
                <mesh geometry={nodes.Object_5.geometry} material={materials.NEON_Green_Light} />
                <mesh geometry={nodes.Object_6.geometry} material={materials.Silver} />
            </group>
        </group>
    );
}

useGLTF.preload('/gltf/mouse/mouse.gltf');
