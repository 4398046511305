/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
author: MAMA's Sneaker Stop (https://sketchfab.com/mamasneakers)
license: CC-BY-4.0 (http://creativecommons.org/licenses/by/4.0/)
source: https://sketchfab.com/3d-models/leather-foot-ball-soccer-408c38d8b34f49c18d1424fa8abd67a8
title: Leather Foot Ball "Soccer"
*/

import React, { useRef } from 'react';
import { useGLTF } from '@react-three/drei';
import { useFrame } from '@react-three/fiber';
import * as THREE from 'three';

export default function Model({ ...props }) {
    const group = useRef();
    const { nodes, materials } = useGLTF('/gltf/football/football.gltf');
    useFrame(state => {
        const t = state.clock.getElapsedTime();
        group.current.rotation.x = THREE.MathUtils.lerp(group.current.rotation.x, Math.cos(t / 2) / 10, 0.1);
        group.current.rotation.y = THREE.MathUtils.lerp(group.current.rotation.y, Math.sin(t / 4) / 10, 0.1);
        group.current.rotation.z = THREE.MathUtils.lerp(group.current.rotation.z, Math.sin(t / 4) / 20, 0.1);
    });
    return (
        <group ref={group} {...props} dispose={null}>
            <group position={[-28.43, 292.05, -147.7]} rotation={[0.63, 0, Math.PI]}>
                <group rotation={[Math.PI / 2, 0, 0]}>
                    <group position={[-14.21, 190.54, 14.26]}>
                        <mesh geometry={nodes.soccer_group_DefaultMaterial_0.geometry} material={materials.DefaultMaterial} />
                    </group>
                </group>
            </group>
        </group>
    );
}

useGLTF.preload('/gltf/football/football.gltf');
