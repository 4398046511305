import React from 'react';
import './SocialButton.scss';

export interface ISocialButtonProps {
    social: 'linkedin' | 'github';
    color: string;
    link: string;
}

export const SocialButton = (props: ISocialButtonProps) => {
    const { social, color, link } = props;
    const invertVal = color == 'white' ? 1 : 0;
    return (
        <div className="socialButton-container" onClick={() => window.open(link, '_blank')}>
            <div className="socialButton-border" />
            {social === 'github' && <img style={{ filter: `invert(${invertVal})` }} className={`socialButton-logo ${social}`} src="/images/github_black.png" alt="GitHub"></img>}
            {social === 'linkedin' && <img style={{ filter: `invert(${invertVal})` }} className={`socialButton-logo ${social}`} src="/svg/linkedin.svg" alt="LinkedIn"></img>}
        </div>
    );
};
