/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
author: OyVeyKitty (https://sketchfab.com/OyVeyKitty)
license: CC-BY-NC-SA-4.0 (http://creativecommons.org/licenses/by-nc-sa/4.0/)
source: https://sketchfab.com/3d-models/fine-point-pen-ccf56d686ca34bf8bb1066845690aa09
title: Fine Point Pen
*/

import React, { useRef } from 'react';
import { useGLTF } from '@react-three/drei';
import { useFrame } from '@react-three/fiber';
import * as THREE from 'three';

export default function Pen({ ...props }) {
    const group = useRef();
    const { nodes, materials } = useGLTF('/gltf/pen/pen.gltf');
    useFrame(state => {
        const t = state.clock.getElapsedTime();
        group.current.rotation.x = THREE.MathUtils.lerp(group.current.rotation.x, Math.cos(t / 2) / 10 + 1, 0.1);
        group.current.rotation.y = THREE.MathUtils.lerp(group.current.rotation.y, Math.sin(t / 4) / 10, 0.1);
        group.current.rotation.z = THREE.MathUtils.lerp(group.current.rotation.z, Math.sin(t / 4) / 20, 0.1);
    });
    return (
        <group ref={group} {...props} dispose={null}>
            <group rotation={[-Math.PI / 2, Math.PI / 2, 0]} scale={0.86}>
                <group rotation={[Math.PI / 2, 0, 0]}>
                    <group position={[-879.31, -545.05, -436.3]} rotation={[0.49, -0.54, 1.91]} scale={100}>
                        <group rotation={[Math.PI / 2, 0, 0]} />
                    </group>
                    <group scale={[11.57, 11.57, 208.18]}>
                        <mesh geometry={nodes.Cylinder_Material001_0.geometry} material={materials['Material.001']} />
                    </group>
                    <group position={[548.56, 567.41, -133.85]} rotation={[1.89, 0.88, -2.05]} scale={100}>
                        <group rotation={[Math.PI / 2, 0, 0]} />
                    </group>
                    <group position={[748.11, 534.37, 650.76]} rotation={[Math.PI, 0.76, 2.68]} scale={100} />
                </group>
            </group>
        </group>
    );
}

useGLTF.preload('/gltf/pen/pen.gltf');
