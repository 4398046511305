/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
author: majkeloon (https://sketchfab.com/majkeloon)
license: CC-BY-4.0 (http://creativecommons.org/licenses/by/4.0/)
source: https://sketchfab.com/3d-models/camera-nikon-1b09063d87d049759147a4788f0a3498
title: Camera Nikon
*/

import React, { useRef } from 'react';
import { useGLTF } from '@react-three/drei';
import { useFrame } from '@react-three/fiber';
import * as THREE from 'three';

export default function Camera({ ...props }) {
    const group = useRef();
    const { nodes, materials } = useGLTF('/gltf/camera/camera.gltf');
    useFrame(state => {
        const t = state.clock.getElapsedTime();
        group.current.rotation.x = THREE.MathUtils.lerp(group.current.rotation.x, Math.cos(t / 2) / 10, 0.1);
        group.current.rotation.y = THREE.MathUtils.lerp(group.current.rotation.y, Math.sin(t / 4) / 10 - 1, 0.1);
        group.current.rotation.z = THREE.MathUtils.lerp(group.current.rotation.z, Math.sin(t / 4) / 20, 0.1);
    });
    return (
        <group ref={group} {...props} dispose={null}>
            <group rotation={[-Math.PI / 2, 0, 0]}>
                <group rotation={[Math.PI / 2, 0, 0]} scale={1}>
                    <group rotation={[-Math.PI / 2, 0, 0]} scale={11.09}>
                        <group scale={9.02}>
                            <mesh geometry={nodes.OTHER_OTHER_0.geometry} material={materials.OTHER} />
                        </group>
                        <group scale={9.02}>
                            <mesh geometry={nodes.CAMER_NIKON_FC_MAIN_MAIN_0.geometry} material={materials.MAIN} />
                        </group>
                    </group>
                </group>
            </group>
        </group>
    );
}

useGLTF.preload('/gltf/camera/camera.gltf');
