/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
author: Rasyinggan 3D (https://sketchfab.com/naraharoufi)
license: CC-BY-4.0 (http://creativecommons.org/licenses/by/4.0/)
source: https://sketchfab.com/3d-models/keyboard-logitech-mx-master-keys-5cba6734bf334450a74ea1c3cde89ef8
title: Keyboard : Logitech MX Master Keys
*/

import React, { useRef } from 'react';
import { useGLTF } from '@react-three/drei';
import { useFrame } from '@react-three/fiber';
import * as THREE from 'three';

export const Keyboard = ({ ...props }) => {
    const group = useRef();
    const { nodes, materials } = useGLTF('/gltf/keyboard/keyboard.gltf');
    useFrame(state => {
        const t = state.clock.getElapsedTime();
        group.current.rotation.x = THREE.MathUtils.lerp(group.current.rotation.x, Math.cos(t / 2) / 10 + 1, 0.1);
        group.current.rotation.y = THREE.MathUtils.lerp(group.current.rotation.y, Math.sin(t / 4) / 10, 0.1);
        group.current.rotation.z = THREE.MathUtils.lerp(group.current.rotation.z, Math.sin(t / 4) / 20, 0.1);
    });
    return (
        <group ref={group} {...props} dispose={null}>
            <group rotation={[-Math.PI / 2, 0, 0]}>
                <mesh geometry={nodes.Material2_1.geometry} material={materials.Color_M09} />
                <mesh geometry={nodes.Material3_1.geometry} material={materials.Metal_bross_clair} />
                <mesh geometry={nodes.Material2_3.geometry} material={materials.Color_M08_1} />
                <mesh geometry={nodes.Material3_3.geometry} material={materials.Color_M03} />
                <mesh geometry={nodes.Material2_4.geometry} material={materials.Color_M09} />
                <mesh geometry={nodes.Material2_5.geometry} material={materials.Color_M09} />
                <mesh geometry={nodes.Material2_6.geometry} material={materials.Color_M09} />
                <mesh geometry={nodes.Material2_7.geometry} material={materials.Translucent_Glass_Gray} />
                <mesh geometry={nodes.Material3_4.geometry} material={materials.material} />
                <mesh geometry={nodes.Material3_5.geometry} material={materials.material_0} />
                <mesh geometry={nodes.Material3_6.geometry} material={materials.Color_M08} />
            </group>
        </group>
    );
};

useGLTF.preload('/gltf/keyboard/keyboard.gltf');
