import React from 'react';
import { SocialButton } from '../SocialButton';
import './SectionHeader.scss';

export interface ISectionHeaderProps {
    header: string;
    background: string;
}

export const SectionHeader = (props: ISectionHeaderProps) => {
    const { header, background } = props;

    return (
        <div className="sectionheader-container" style={{ color: 'black' }}>
            {/* <img className={`g-logo`} src="/svg/g.svg" alt="geireann"></img> */}
            <div className="sectionheader-name">
                geireann
                <div
                    className="sectionheader-header"
                    style={{
                        backgroundImage: background,
                    }}>
                    .{header}
                </div>
            </div>
        </div>
    );
};
