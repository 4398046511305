import React from 'react';
import { PAGE } from '../../App';
import { SocialButton } from '../SocialButton';
import './Header.scss';
import pages from '../../pages.json';

export interface IHeaderProps {
    setPage: (page: PAGE) => void;
    page: PAGE;
    color: string;
}

export const Header = (props: IHeaderProps) => {
    const { color, setPage, page } = props;

    const renderMenu = (): JSX.Element => {
        const pageList = Object.values(PAGE);

        return (
            <div className="menu-container">
                {pageList.map(p => {
                    return <div style={{ background: p == page ? `linear-gradient(${Math.random() * 90}deg, ${pages[page].gradient1},  ${pages[page].gradient2})` : undefined }} className={`menu-button ${p == page}`} onClick={() => setPage(p)}></div>;
                })}
            </div>
        );
    };

    return (
        <div className="header-container" style={{ color: color }}>
            {/* <img className={`g-logo`} src="/svg/g.svg" alt="geireann"></img> */}
            <div className="header-name">geireann</div>
            <div className="header-menu">{renderMenu()}</div>
            <div className="header-socialButtons">
                <SocialButton social="github" color={color} link={'https://github.com/geireann'} />
                <SocialButton social="linkedin" color={color} link={'https://www.linkedin.com/in/geireann/'} />
            </div>
        </div>
    );
};
