export interface IClass {
    department: string;
    courseNumber: string;
    courseTitle: string;
    teacher?: string;
    semester: string;
    courseWebsite?: string;
}

export const classes: IClass[] = [
    {
        department: 'CS',
        courseNumber: '0150',
        courseTitle: 'Introduction to Object-Oriented Programming in Java',
        teacher: 'Andries van Dam',
        semester: 'Fall 2019',
        courseWebsite: 'https://cs.brown.edu/courses/cs015/',
    },
    {
        department: 'CS',
        courseNumber: '0160',
        courseTitle: 'Introduction to Algorithms and Data Structures',
        teacher: 'Seny Kamara & Doug Woos',
        semester: 'Spring 2020',
        courseWebsite: 'http://cs.brown.edu/courses/csci0160/',
    },
    {
        department: 'CS',
        courseNumber: '0300',
        courseTitle: 'Fundamentals of Computer Systems',
        teacher: 'Malte Schwarzkopf',
        semester: 'Spring 2021',
        courseWebsite: 'http://cs.brown.edu/courses/csci0300/2022/',
    },
    {
        department: 'CHIN',
        courseNumber: '0300',
        courseTitle: 'Intermediate Chinese I',
        semester: 'Fall 2019',
    },
    {
        department: 'RELS',
        courseNumber: '0090M',
        courseTitle: 'Islam, Violence and Media',
        semester: 'Fall 2019',
    },
    {
        department: 'SOC',
        courseNumber: '1620',
        courseTitle: 'Globalization and Social Conflict',
        semester: 'Fall 2019',
    },
    {
        department: 'CHIN',
        courseNumber: '0400',
        courseTitle: 'Intermediate Chinese II',
        semester: 'Spring 2020',
    },
    {
        department: 'ECON',
        courseNumber: '0110',
        courseTitle: 'Principles of Economics',
        semester: 'Spring 2020',
    },
    {
        department: 'HIST',
        courseNumber: '0150D',
        courseTitle: 'Refugees: A 20th Century History',
        semester: 'Spring 2020',
    },
    {
        department: 'MATH',
        courseNumber: '0520',
        courseTitle: 'Linear Algebra',
        semester: 'Spring 2020',
    },
    {
        department: 'APMA',
        courseNumber: '1650',
        courseTitle: 'Statistical Inference I',
        semester: 'Spring 2021',
    },
    {
        department: 'CS',
        courseNumber: '1951V',
        courseTitle: 'Hypertext / Hypermedia',
        semester: 'Fall 2021',
    },
    {
        department: 'IAPA',
        courseNumber: '0200',
        courseTitle: 'Foundations of Development',
        semester: 'Spring 2021',
    },
    {
        department: 'SOC',
        courseNumber: '1490',
        courseTitle: 'Power, Knowledge and Justice in Global Social Change',
        semester: 'Spring 2021',
    },
    {
        department: 'CS',
        courseNumber: '1951A',
        courseTitle: 'Data Science',
        semester: 'Summer 2021',
    },
    {
        department: 'CS',
        courseNumber: '0123',
        courseTitle: 'Computer Graphics',
        semester: 'Fall 2021',
    },
    {
        department: 'CS',
        courseNumber: '1470',
        courseTitle: 'Deep Learning',
        semester: 'Fall 2021',
    },
];
