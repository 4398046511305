import React, { useEffect, useState } from 'react';
import './App.scss';
import { ReactComponent as Logo } from './assets/me-icon.svg';
import { ReactComponent as Grain } from './assets/grain.svg';
import { CanvasContainer } from './canvas';
import { SocialButton } from './components/SocialButton';
import { Header } from './components';
import * as fa from 'react-icons/fa';
import { SectionHeader } from './components/SectionHeader/SectionHeader';
import { Content } from './components/Content';
import pages from './pages.json';

export enum PAGE {
    SWE = 'software_engineer',
    PHOTO = 'photographer',
    STUDENT = 'student',
    FOOT = 'footballer',
}

function App() {
    const [isSidebarOpen, setSidebarOpen] = useState(false);

    useEffect(function setupListener() {
        const keyboardEvent = (e: KeyboardEvent) => {
            switch (e.key) {
                case 'ArrowRight':
                    if (pageIndex + 1 > Object.values(PAGE).length - 1) break;
                    setPage(Object.values(PAGE)[pageIndex + 1]);
                    setPageIndex(pageIndex + 1);
                    break;
                case 'ArrowLeft':
                    if (pageIndex - 1 < 0) break;
                    setPage(Object.values(PAGE)[pageIndex - 1]);
                    setPageIndex(pageIndex - 1);
                    break;
            }
        };

        window.addEventListener('keydown', keyboardEvent);

        return function cleanupListener() {
            window.removeEventListener('keydown', keyboardEvent);
        };
    });

    const [pageIndex, setPageIndex] = useState<number>(0);

    const [page, setPage] = useState<PAGE>(PAGE.SWE);

    const [isAboutMeOpen, setAboutMeOpen] = useState<boolean>(false);

    return (
        <div className="App">
            {/* ABOUT ME */}
            <div className="aboutMe-button" style={{ color: pages[page].color }} onPointerEnter={() => setAboutMeOpen(true)} onPointerLeave={() => setAboutMeOpen(false)}>
                <div className="background" style={{ width: isAboutMeOpen ? '130%' : '0%', background: pages[page].gradient1 }}></div>
                about me
                <div className="toggle">
                    <div className={`vertical ${!isAboutMeOpen}`} style={{ background: pages[page].color }}></div>
                    <div className={`horizontal ${!isAboutMeOpen}`} style={{ background: pages[page].color }}></div>
                </div>
            </div>

            <div className="aboutMe-container" style={{ color: pages[page].color, bottom: isAboutMeOpen ? 20 : -300 }}>
                I am a Junior pursuing a Sc.B. in Computer Science at Brown University. I am a full stack developer with an interest in Human Computer Interaction, Visual Computing, the future of web computing, UI/UX, and figuring out how we can use
                technology for the right reasons. This is my personal website which I (hope to) also use to post some of my thoughts and ideas.
            </div>

            {/* I'M A...*/}
            <div className="info" style={{ color: pages[page].color }}>
                {pages[page].text}
            </div>

            {/* CANVAS */}
            <CanvasContainer page={page} />

            {/* BACKGROUND */}
            <div className="background">
                {/* HEADER */}
                <Header color={pages[page].color} setPage={setPage} page={page} />
                <div className="sidebar"></div>
                <div className="gradient" />
                <div
                    className="grain"
                    style={{
                        background: `linear-gradient(${Math.random() * 90}deg, ${pages[page].gradient1},  ${pages[page].gradient2})`,
                    }}>
                    <Grain />
                </div>
            </div>

            {/* I'M A...*/}
            {pages[page].more != '' && (
                <div
                    className="contentScroll-container"
                    onClick={() => {
                        window.scrollTo({
                            top: window.innerHeight,
                            left: 0,
                            behavior: 'smooth',
                        });
                    }}
                    style={{ color: pages[page].color }}>
                    <div>{pages[page].more}</div>
                    <div className="contentScroll-caret">
                        <fa.FaCaretDown />
                    </div>
                </div>
            )}

            <Content page={page} />
        </div>
    );
}

export default App;
