export interface IProject {
  title: string
  description: string
  imageUrl: string
  startDate: Date
  endDate?: Date
  appUrl?: string
  videoUrl?: string
  infoUrl?: string
  githubUrl?: string
  collaborators?: string[]
  class?: string
  technologies?: string[]
}

export const projects: IProject[] = [
  {
    title: 'Quiz Makr',
    description:
      'Web application that is compatible in mobile browsers for authoring and sharing Buzzfeed style quizzes. Built as part of the Brown Graphics Lab for our sponsors Apex.',
    imageUrl: 'https://imgur.com/SYjqKQk.jpg',
    startDate: new Date('2020-03'),
    technologies: [
      'MongoDB',
      'Express',
      'React',
      'Node.js',
      'Typescript',
      'HTML',
      'Python',
      'Firebase',
      'Redux',
    ],
  },
  {
    title: 'Dash',
    description:
      'Dash is a collaborative browser-based web application built to support knowledge workers.',
    imageUrl: 'https://imgur.com/30vpvpb.jpg',
    startDate: new Date('2020-03'),
    appUrl: 'https://browndash.com/signup',
    infoUrl: 'https://brown-dash.github.io/Dash-Documentation/about/',
    collaborators: ['Andries van Dam', 'Bob Zeleznik'],
    technologies: [
      'MongoDB',
      'Express',
      'React',
      'Node.js',
      'Typescript',
      'HTML',
      'Azure',
    ],
  },
  {
    title: 'Marching Cubes',
    description:
      'This project uses a marching cubes approach to procedurally generate new terrain. We aimed to create an ice cave system.',
    imageUrl: 'https://imgur.com/nXxJNAy.jpg',
    startDate: new Date('2020-03'),
    videoUrl:
      'https://drive.google.com/file/d/1q0G3UL4MlqzRMiS8Ri9rjQyJyI2GFjRO/view?usp=sharing',
    githubUrl: 'https://github.com/cs123tas/final-project-jpierce6-pmolnar-glindfie',
    collaborators: ['Joshua Pierce', 'Paul Molnar'],
    technologies: ['C++', 'Unity'],
  },
  {
    title: 'NLP Prediction Model',
    description:
      'We built a language classification model that estimates course ratings based on Critical Review feedback. This model is built using a Bidirection LSTM model.',
    imageUrl: 'https://imgur.com/BTjw1f5.jpg',
    startDate: new Date('2020-03'),
    videoUrl: 'https://youtu.be/mQ6295nucFg',
    infoUrl:
      'https://docs.google.com/document/d/1E-heY-2DSKG8mOehzU5YepowNYiMriYEZ20QfBknnRo/edit?usp=sharing',
    collaborators: ['Xinzhe Chai', 'Jinoo Hong', 'Peter Li'],
    technologies: ['Python', 'Tensorflow', 'Keras', 'Numpy', 'Pandas'],
  },
  {
    title: 'Dot Dot Data',
    description:
      'Positional analysis of chess games to predict the outcome of a chess game at a given board position.',
    imageUrl: 'https://imgur.com/c8wdhY4.jpg',
    startDate: new Date('2020-03'),
    githubUrl: 'https://github.com/geireann/dot.dot.data',
    videoUrl:
      'https://www.youtube.com/watch?v=39F6YnF0d70&feature=youtu.be&ab_channel=DhirajKhanal',
    collaborators: ['Xinzhe Chai', 'Jinoo Hong', 'Peter Li'],
    class: 'CS1951a',
    technologies: ['Python', 'Pandas', 'Numpy', 'sql', 'BeautifulSoup', 'Data Science'],
  },
  {
    title: 'MyHypermedia',
    description: 'Stencil app build for students in CS1951V.',
    imageUrl: 'https://imgur.com/1mL5jLU.jpg',
    startDate: new Date('2021-06'),
    endDate: new Date('2021-08'),
    appUrl: 'https://basic-hypermedia.web.app/',
    infoUrl: 'http://cs.brown.edu/courses/csci1951-v/',
    collaborators: ['Xinzhe Chai', 'Jinoo Hong', 'Peter Li'],
    technologies: [
      'MongoDB',
      'Remirror',
      'Express',
      'React',
      'Node.js',
      'Typescript',
      'Javascript',
      'HTML',
      'Heroku',
      'Firebase',
    ],
  },
  {
    title: 'Vunmo',
    description:
      'In this project I implemented the multi-threaded backend of a Venmo-like banking service, as well as a useful synchronized data structure. ',
    imageUrl:
      'https://time.com/nextadvisor/wp-content/uploads/2020/10/na-venmo-guide.jpg',
    startDate: new Date('2020-03'),
    technologies: ['C++'],
  },
  {
    title: 'floop',
    description:
      'floop is a project from Hack@Brown (mobile app and web-page) which connects individuals to contactless drop-off locations to donate excess non-perishable goods and utensils.',
    imageUrl: 'https://imgur.com/TlS6FT2.jpg',
    startDate: new Date('2020-03'),
    videoUrl: 'https://youtu.be/8g1AuYJIPtE',
    infoUrl: 'https://devpost.com/software/floop',
    collaborators: ['Xinzhe Chai', 'Jinoo Hong', 'Peter Li'],
    technologies: ['Java', 'Kotlin'],
  },
  {
    title: 'Dash Mobile',
    description:
      'Built the mobile version of Dash. Supported on Android and Apple as well as natively in browsers.',
    imageUrl: 'https://imgur.com/2VUuS6C.jpg',
    startDate: new Date('2020-03'),
    videoUrl: 'https://youtube.com/playlist?list=PLkT2mQEOya8-7s13w0KlXtcuDXG8PCNSK',
    collaborators: ['Xinzhe Chai', 'Jinoo Hong', 'Peter Li'],
    technologies: ['React Native', 'Typescript', 'React'],
  },
  {
    title: 'Dash Documentation',
    description:
      'Easily editable help pages written in Markdown to support users in Dash.',
    imageUrl: 'https://imgur.com/bxitipM.jpg',
    startDate: new Date('2020-03'),
    appUrl: 'https://brown-dash.github.io/Dash-Documentation/',
    technologies: ['Markdown', 'Jekyll', 'Github Pages'],
  },
  {
    title: 'CS1951V Course Website',
    description: 'Course website for students taking CS1951V',
    imageUrl: 'https://imgur.com/9OTQNpr.jpg',
    startDate: new Date('2020-03'),
    appUrl: 'http://cs.brown.edu/courses/csci1951-v/',
    technologies: ['Typescript', 'React', 'HTML', 'SCSS'],
  },
]
