import react from 'React';
import { PAGE } from '../../App';
import { SectionHeader } from '../SectionHeader/SectionHeader';
import pages from '../../pages.json';
import './Content.scss';
import { projects } from '../../data/projects';
import Masonry from 'react-masonry-css';
import { IPhoto, photos } from '../../data/photos';
import { classes } from '../../data/classes';

export interface IContentProps {
    page: PAGE;
}
export const Content = (props: IContentProps) => {
    const { page } = props;
    const breakpointColumnsObj = {
        default: 4,
        1100: 3,
        700: 2,
        500: 1,
    };
    const getCollection = (): JSX.Element => {
        const children: JSX.Element[] = [];

        if (page == PAGE.PHOTO) {
            return (
                <Masonry breakpointCols={breakpointColumnsObj} className={`collection ${page}`} columnClassName="my-masonry-grid_column">
                    {photos &&
                        photos.map((photo: IPhoto) => {
                            return (
                                <div className={`collection-item ${page}`}>
                                    <img className={'photo'} src={photo.src} alt={photo.alt}></img>
                                </div>
                            );
                        })}
                </Masonry>
            );
        }

        if (page == PAGE.STUDENT) {
            classes.forEach(val => {
                children.push(
                    <div className={`collection-item ${page}`}>
                        <div className="project-title">{val.courseTitle}</div>
                        <div className="project-description">
                            {val.department}
                            {val.courseNumber}
                        </div>
                    </div>
                );
            });
        }

        if (page == PAGE.SWE) {
            projects.forEach(proj => {
                children.push(
                    <div className={`collection-item ${page}`}>
                        <img className="project-image" src={proj.imageUrl} alt={proj.title}></img>
                        <div className="project-title">{proj.title}</div>
                        <div className="project-description">{proj.description}</div>
                        {proj.technologies && (
                            <div className="project-technologies">
                                {proj.technologies.map(tech => {
                                    return <div className="project-technology">{tech}</div>;
                                })}
                            </div>
                        )}
                    </div>
                );
            });
        }
        return <div className={`collection ${page}`}>{children}</div>;
    };

    return (
        <div className="content-container">
            <SectionHeader background={`linear-gradient(${Math.random() * 90}deg, ${pages[page].gradient1},  black)`} header={pages[page].more} />
            {getCollection()}
        </div>
    );
};
